<template>
  <div class="guangxi">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/lt_gx1_01.png"
    />
    <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
      />
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <img
        class="two"
        v-show="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
      />
      <img
        class="three"
        v-show="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
      />
      <van-field
        v-model="form.code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div>
            <div class="code" v-show="sendAuthCode" @click="getAuthCode">
              获取验证码
            </div>
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijikaitong.gif"
        class="submit"
        @click="onSubmit"
      />
      <img
        class="four"
        v-show="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif"
      />
    </div>
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/lt_gx1_02.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/lt_gx1_03.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/lt_gx1_07.jpg"
    />
    <van-popup
      v-model="showSubmitDialog"
      :close-on-click-overlay="false"
      style="background: none"
    >
      <div class="dialog_wrap">
        <img
          class="dialog-img"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/GuangXi/YoukuWatch/dialog_bg.png"
        />
        <img
          class="cancel"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/GuangXi/YoukuWatch/cancel.png"
          @click="showSubmitDialog = false"
        />
        <img
          class="confirm"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/GuangXi/YoukuWatch/confirm.png"
          @click="handleSubmit"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  components: {},
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "46039-8858",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      showSubmitDialog: false,
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/ALL/yd_fusion`;
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href),
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      // 返回拦截
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, that.form.phone, "退出")
      );
    };
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 验证码
    getAuthCode() {
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        if (this.yzm_loading) return;
        this.yzm_loading = true;
        getVerificationCode(JSON.stringify(this.form))
          .then((res) => {
            this.yzm_loading = false;
            if (res.code != 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              setTimeout(() => {
                window.location.replace(
                  this.tailJumpUrl(
                    this.link,
                    `QIUMORONGHE`,
                    this.form.phone,
                    "验证码下发失败"
                  )
                );
              }, 3000);
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            // console.log(error);
            this.yzm_loading = false;
          });
      }
    },

    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.close();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "成功"
              )
            );
          }, 3000);
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else {
        this.showSubmitDialog = true;
      }
    },
    close() {
      this.$toast.clear();
      this.showSubmitDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.guangxi {
  background-color: #78e7f8;

  .img {
    width: 100%;
  }

  .form {
    box-sizing: border-box;
    padding-bottom: 20px;
    position: relative;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(90px, -80px);
    }

    .two {
      width: 152px;
      right: 40px;
      top: 50px;
      transform: translate(-2px, 10px);
    }

    .three {
      width: 430px;
      transform: translate(90px, -20px);
    }

    .four {
      width: 246px;
      transform: translate(270px, -76px);
    }

    ::v-deep .van-cell {
      width: 640px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 20px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 56px;

      &.input-code {
        margin-top: 40px;

        .code {
          width: 229px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          color: #bc0d11;
          font-size: 30px;
          font-weight: 500;
          border-left: solid 2px #bc0d11;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 600px;
      display: block;
      margin: 50px auto 60px;
      animation: identifier 1.5s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.15);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.15);
        }
      }
    }
  }
  .dialog_wrap {
    width: 720px;
    height: 707px;
    position: relative;
    .dialog-img {
      width: 100%;
      height: 100%;
    }
    .cancel {
      position: absolute;
      left: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
    .confirm {
      position: absolute;
      right: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
  }
}
</style>
